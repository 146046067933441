import React, { useEffect, useState } from "react";
import { getRequest } from "../../../helper/axiosClient";
import { errorNotification } from "../../../helper/notification";
import Header from "../../commonPages/Header";
import { COURSE_LIST } from "../../constant/apiEndPoint";
import Banner from "./Banner";
import Blog from "./Blog";
import Contact from "./Contact";
import OurClient from "./OurClient";
import Servicediffrent from "./Servicediffrent";
import Upcomingcourse from "./Upcomingcourse";
import Whatweprovide from "./Whatweprovide";
import Footer from "../../commonPages/Footer";
import WhyChooseUs from "./WhyChooseUs";

const Home = () => {
  const [courseList, setCourseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCourseAndUsersData = async () => {
    setIsLoading(true);
    try {
      const res = await getRequest(COURSE_LIST);
      if (res?.data?.status === 1) {
        setCourseList(res?.data?.data);
      }
      if (res.data.type === 2) {
        errorNotification(res.data.errormessage);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCourseAndUsersData();
  }, []);

  return (
    <>
      {/* Main content Start */}
      <div className="main-content" id="home">
        <Header />
        <Banner />
        <Whatweprovide />
        <WhyChooseUs />
        <Upcomingcourse courseList={courseList} />
        <Servicediffrent courseList={courseList} />
        <Blog />
        <OurClient />
        <Contact />
      </div>
      <Footer />
      {/* Main content End */}
    </>
  );
};

export default Home;
