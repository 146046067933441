import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { postRequest } from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { CREATE_STUDENT, PAYPAL_ORDER } from "../../constant/apiEndPoint";
import { getCurrentUserFromLocalStorage, setCurrentUser } from "../../../localStorage/setCurrentUser";

const StudentRegisterPopup = ({
  courseId,
  isOpen,
  setIsOpenPopup,
  setCourseId,
  courseDetails,
  setCourseStatusForStudent,
}) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const navigate = useNavigate();
  const currentUser = getCurrentUserFromLocalStorage();

  const handleClose = () => {
    setIsOpenPopup(false);
    setCourseId(null);
    formik.resetForm();
  };

  const handleClosePayment = () => {
    setOpenPaymentModal(false);
  };

  // function PaymentButton() {
  //   const [{ isPending }] = usePayPalScriptReducer();
  //   const paypalbuttonTransactionProps = {
  //     style: { layout: "vertical" },
  //     createOrder(data, actions) {
  //       return actions.order.create({
  //         purchase_units: [
  //           {
  //             description: "122123213212",
  //             amount: {
  //               value: "0.01",
  //               currency_code: "USD",
  //             },
  //           },
  //         ],
  //       });
  //     },
  //     onApprove(data, actions) {
  //       return actions.order.capture({}).then((details) => {
  //         alert(
  //           "Transaction completed by " +
  //           (details?.payer.name.given_name ?? "No details")
  //         );

  //         alert("Data details: " + JSON.stringify(data, null, 2));
  //       });
  //     },
  //   };
  //   return (
  //     <>
  //       {isPending ? <h2>Load Smart Payment Button...</h2> : null}
  //       <PayPalButtons {...paypalbuttonTransactionProps} />
  //     </>
  //   );
  // }

  // const PayPalButton = ({ amount, onSuccess, onError }) => {
  //   const paypalRef = useRef();

  //   useEffect(() => {
  //     window.paypal
  //       .Buttons({
  //         createOrder: (data, actions) => {
  //           return actions.order.create({
  //             purchase_units: [
  //               {
  //                 amount: {
  //                   value: amount,
  //                 },
  //               },
  //             ],
  //           });
  //         },
  //         onApprove: async (data, actions) => {
  //           const order = await actions.order.capture();
  //           onSuccess(order);
  //         },
  //         onError: (err) => {
  //           onError(err);
  //         },
  //       })
  //       .render(paypalRef.current);
  //   }, [amount, onSuccess, onError]);

  //   return <div ref={paypalRef}></div>;
  // };

  // const handleSuccess = (order) => {
  //   console.log('Order successful:', order);
  //   // Handle successful payment here (e.g., show a success message, update the database, etc.)
  // };

  // const handleError = (err) => {
  //   console.error('Payment error:', err);
  //   // Handle payment error here (e.g., show an error message)
  // };

  const validationSchema = Yup.object({
    name: Yup.string().required("Full Name is required."),
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
    phoneNumber: Yup.string()
      .required("Phone Number is required.")
      .matches(/^\d{11}$/, "Must be exactly 11 digits"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (+courseDetails?.price === 0) {
        createStudent(values, resetForm);
      } else {
        // setOpenPaymentModal(true);
        paypalPayment(values);
      }
    },
  });

  const createStudent = async (values, resetForm) => {
    try {
      const payload = { ...values, courseId: courseId };
      const res = await postRequest(CREATE_STUDENT, payload, navigate);

      if (res.type === 1) {
        setCourseStatusForStudent("Pending");
        successNotification(res?.response?.data?.message);
        setCurrentUser(res?.response?.data?.user);
        resetForm();
        handleClose();
      }
      if (res.type === 0) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.error("res====>", error);
    }
  };

  const paypalPayment = async (values) => {
    try {
      const payload = {
        userId: currentUser?._id,
        currency: "USD",
        items: [
          {
            name: courseDetails?.name,
            price: courseDetails?.price,
            currency: "USD",
            quantity: 1
          }
        ]
      };
      const res = await postRequest(PAYPAL_ORDER, payload);
      if (res.type === 1) {
        window.location = res?.response?.data?.link;
        successNotification(res?.response?.data?.message);
        localStorage.setItem("payValues", JSON.stringify({ ...values, courseId: courseId }));
        // handleClose();
      }
      if (res.type === 0) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.error("res====>", error);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        centered
        backdrop="static"
        className="CommomForm"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Details</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="login-2">
              <div className="row login-box">
                <div className="col-lg-12 col-md-12 form-info">
                  <div className="form-section">
                    <div className={" login-inner-form"}>
                      <div className="form-group form-box">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Enter Your Full Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter Your Email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group form-box">
                        <input
                          type="text"
                          name="phoneNumber"
                          className="form-control"
                          id="phoneNumber"
                          placeholder="Enter Your Phone Number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                        />
                        {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber ? (
                          <div
                            className="help-block with-errors"
                            style={{ color: "#F0BD6C", fontSize: "14px", }}
                          >
                            * {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="contact-form-btn">
              <button type="submit" className="btn-default">
                Book Now
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {/* <Modal
        show={openPaymentModal}
        onHide={handleClosePayment}
        backdrop="static"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Pay Course Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentButton />
          <Button className="paypalBtn" onClick={() => paypalPayment()}><img src="/assets/images/paypal.svg" alt="paypal" /></Button>
          <PayPalButton amount={courseDetails?.price} onSuccess={handleSuccess} onError={handleError} />
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default StudentRegisterPopup;
