import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import 'animate.css/animate.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import WOW from 'wowjs';
import App from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(document.getElementById('root'));

const paypalScriptOptions = {
  "client-id": "AdZzEm8-dd4LPGzyX6D0rDhMXJj404mzqu18s0y82P2fev0Hcz4P7FLBzwcbnPqW10h7IqydKcXNObMq",
  currency: "USD"
};

const wow = new WOW.WOW({ live: false });
wow.init();

root.render(
  <BrowserRouter>
    <PayPalScriptProvider options={paypalScriptOptions}>
      <App />
    </PayPalScriptProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
