import React from "react";
import "./ReflectivePractice.css";

const ReflectivePractice = () => {
  return (
    // <div className="reflective-section">
    <div className="reflective-container" >
      <h3 className="reflective-heading">Reflective Practice</h3>
      <div className="reflective-subheading">
        Course: Safeguarding of Children & Vulnerable Adults (Level 1)
        &nbsp;&nbsp;|&nbsp;&nbsp; Date: 22/04/2024
      </div>
      <div className="faq-list">
        <div className="faq">
          <div className="faq-header">
            <h3 className="faq-title">What did you learn from this course?</h3>
          </div>
          <div className="faq-body">
            <div className="faq-text">
              I learned about the specific practices and procedures for
              infection control in a dental setting, ensuring a safe environment
              for both patients and staff. The course equipped me with knowledge
              on data protection regulations, emphasizing the importance of
              protecting patient confidentiality.
            </div>
          </div>
        </div>
        <div className="faq">
          <div className="faq-header">
            <h3 className="faq-title">
              How will you apply the knowledge you have learned how valuable was
              this course in your profession? to your profession?
            </h3>
          </div>
          <div className="faq-body">
            <div className="faq-text">
              This Level 1 Compliance course equipped me with knowledge in
              essential areas like infection control and data protection. I'm
              committed to staying informed through GDC, CDC, and HSE resources.
              By integrating this knowledge into my work, I aim to contribute to
              a safe and compliant dental practice, ensuring the wellbeing of
              patients and staff.
            </div>
          </div>
        </div>
        <div className="faq">
          <div className="faq-header">
            <h3 className="faq-title">
              How do you intend to maintain the knowledge you have learned for
              the future?
            </h3>
          </div>
          <div className="faq-body">
            <div className="faq-text">
              To retain this knowledge, I will leverage GDC, CDC & HSE updates,
              user interactions for refinement, and integration with evolving
              dental knowledge.
            </div>
          </div>
        </div>
        <div className="faq">
          <div className="faq-header">
            <h3 className="faq-title">
              How valuable was this course in your profession?
            </h3>
          </div>
          <div className="faq-body">
            <div className="faq-text">
              The Dental Compliance Level 1 course proved invaluable to my
              professional development. It provided a solid foundation in key
              areas like infection control and data protection, essential for
              maintaining a safe and compliant dental environment. I'm confident
              this knowledge will be a valuable asset to my role within the
              dental practice.
            </div>
          </div>
        </div>
        <div className="faq">
          <div className="faq-header">
            <h3 className="faq-title">
              How will this new knowledge impact on your current job role?
            </h3>
          </div>
          <div className="faq-body">
            <div className="faq-text">
              This ensures users have access to reliable and current details on
              dental compliance regulations in the UK. I can tailor my responses
              to incorporate specific compliance concerns, whether it's
              infection control practices or data protection best practices.
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default ReflectivePractice;
