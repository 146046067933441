import { useFormik } from "formik";
import Cookies from "js-cookie";
import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { postRequest } from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { SIGN_UP } from "../../constant/apiEndPoint";
import basestyle from "../Base.module.css";

const Register = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("This is not a valid email format!")
      .required("Email is required."),
    password: Yup.string()
      .min(4, "Password must be more than 4 characters")
      .max(10, "Password cannot exceed more than 10 characters")
      .required("Password is required."),
    phoneNumber: Yup.string()
      .required("Phone Number is required.")
      .matches(/^\d{11}$/, "Must be exactly 11 digits"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phoneNumber: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // Handle form submission
      try {
        const res = await postRequest(SIGN_UP, values);
        if (res.type === 1) {
          Cookies.set("token", res?.response?.data?.token);
          successNotification(res?.response?.data?.message);
          navigate("/");
        }
        if (res.type === 0) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.error("res====>", error);
      }
    },
  });

  return (
    <>
      {/* Login 2 start */}
      <div className="login-2 medical-services">
        <div className="container">
          <div className="row login-box">
            <div className="col-lg-6 col-md-12 bg-img">
              <div className="info">
                <div className="info-text" style={{ textAlign: "center" }}>
                  <div className="waviy">
                    <span>welcome</span> <br />
                    <span className="color-yellow">to</span> <br />
                    <span>sage Academy</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 form-info">
              <div className="form-section">
                <div className="logo clearfix">
                  <Link to="/">
                    <img
                      src="assets/logo/Sage%20Academy%20Inverse%20Long.png"
                      alt="logo"
                    />
                  </Link>
                </div>
                <h3>Create your account</h3>
                <div className={"login-inner-form"}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group form-box">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <p className={basestyle.error}>{formik.errors.name}</p>
                      ) : null}
                      <i className="flaticon-user"></i>
                    </div>
                    <div className="form-group form-box">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p className={basestyle.error}>{formik.errors.email}</p>
                      ) : null}
                      <i className="flaticon-mail-2" />
                    </div>
                    <div className="form-group form-box">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <p className={basestyle.error}>
                          {formik.errors.password}
                        </p>
                      ) : null}
                      <i className="flaticon-password" />
                    </div>
                    <div className="form-group form-box">
                      <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Phone Number"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                      />
                      {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                        <p className={basestyle.error}>
                          {formik.errors.phoneNumber}
                        </p>
                      ) : null}
                      <i className="fa fa-phone-flip"></i>
                    </div>
                    <div className="form-group mb-0">
                      <button
                        className={
                          basestyle.button_common + " btn-md btn-theme w-100"
                        }
                        type="submit"
                        disabled={!(formik.isValid || formik.dirty)}
                      >
                        Register
                      </button>
                    </div>
                    <p className="text">
                      <NavLink to="/login">Already registered? Login</NavLink>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login 2 end */}
    </>
  );
};

export default Register;
