import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import SplitType from 'split-type';
import { getRequest } from '../../../helper/axiosClient';
import { BLOG_LIST } from '../../constant/apiEndPoint';
import { errorNotification } from '../../../helper/notification';
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

function Blog() {
  const navigate = useNavigate();
  const [blogList, setBlogList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const elementRef = useRef(null);

  const getBlogList = async () => {
    setIsLoading(true)
    try {
      const res = await getRequest(BLOG_LIST);
      if (res?.data?.status === 1) {
        setBlogList(res?.data?.data);
        // successNotification(res?.data?.message);
      }
      if (res.data.type === 2) {
        errorNotification(res.data.errormessage);
      }
    } catch (error) {
      console.error("error", error);
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getBlogList()
  }, [])

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;

      if (element.animation) {
        element.animation.progress(1).kill();
      }

      let split = new SplitType(element, { types: 'lines,words,chars' });
      gsap.set(element, { perspective: 400 });

      gsap.set(split.chars, {
        opacity: 0,
        x: "50",
      });

      element.animation = gsap.to(split.chars, {
        scrollTrigger: { trigger: element, start: "top 90%" },
        x: "0",
        y: "0",
        rotateX: "0",
        opacity: 1,
        duration: 1,
        ease: "back.out",
        stagger: 0.02,
      });
    }
  }, []);

  return (
    <>
      {/* Recent Posts Section Start */}
      <div className="recent-post">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 className="wow fadeInUp">our blog</h3>
                <h2 className="text-anime-style-3" ref={elementRef}>Lates News &amp; Articles.</h2>
              </div>
              {/* Section Title End */}
            </div>
          </div>
          <Swiper
            className='blogSlider'
            spaceBetween={50}
            loop={true}
            navigation={true}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination]}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2.5,
              },
              992: {
                slidesPerView: 3,
              },
            }}
          >
            {blogList?.length > 0 && blogList?.map((item) => {
              return (
                <SwiperSlide key={item?._id} onClick={() =>
                  navigate(`/blog-details?id=${item._id}`)
                }>
                  {/* Post Item Start */}
                  <div className="team-member-item post-item  wow fadeInUp" data-wow-delay="0.75s">
                    {/* Team Image Start */}
                    <div className="team-image post-featured-image">
                      <figure className="image-anime">
                        {item?.blogImage && item?.blogImage[0] && (
                          <img
                            alt={item?.blogImage[0]}
                            src={
                              process.env.REACT_APP_IMAGE_API_URL +
                              item?.blogImage[0]
                            }
                          />
                        )}
                      </figure>
                      {/* Team Social List Start */}
                      {/* <div className="team-social-list">
                        <ul>
                          <li>
                            <a href="https://www.instagram.com/sagedentaluk/" className="social-icon" target='_blank'>
                              <i className="fa-brands fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkedin.com/company/sagedentaluk/" className="social-icon" target='_blank'>
                              <i className="fa-brands fa-linkedin-in" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.facebook.com/sagedentaluk" className="social-icon" target='_blank'>
                              <i className="fa-brands fa-facebook-f" />
                            </a>
                          </li>
                        </ul>
                      </div> */}
                      {/* Team Social List End */}
                    </div>
                    {/* Team Image End */}
                    {/* Post Content Start */}
                    <div className="post-item-body">
                      <h2>
                        <Link to={`/blog-details?id=${item._id}`}>{item?.name}</Link>
                      </h2>
                      {/* <p>Hypertension, commonly known as high blood pressure, is a prevalent</p> */}
                    </div>
                    {/* Post Content End */}
                    {/* Btn Readmore Start */}
                    {/* <div className="btn-readmore">
                                    <a href="#">
                                        read more <i className="fa-solid fa-arrow-right-long" />
                                    </a>
                                </div> */}
                    {/* Btn Readmore End */}
                  </div>
                  {/* Post Item End */}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
      {/* Recent Posts Section End */}
    </>


  )
}

export default Blog