import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';

const handleLinkClick = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

// const heroStyle = {
//     position: 'relative',
//     overflow: 'hidden',
// };

// const videoBackgroundStyle = {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     overflow: 'hidden',
// };

// const iframeStyle = {
//     width: '100vw',
//     height: '100vh',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     border: 'none',
//     pointerEvents: 'none',
// };

// const overlayStyle = {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
//     zIndex: 1, // Ensure it is above the video but below the content
// };

const contentStyle = {
  position: 'relative',
  zIndex: 2, // Ensure content is above the overlay
};

function Banner() {

  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;

      if (element.animation) {
        element.animation.progress(1).kill();
      }

      let split = new SplitType(element, { types: 'lines,words,chars' });
      gsap.set(element, { perspective: 400 });

      gsap.set(split.chars, {
        opacity: 0,
        x: "50",
      });

      element.animation = gsap.to(split.chars, {
        scrollTrigger: { trigger: element, start: "top 90%" },
        x: "0",
        y: "0",
        rotateX: "0",
        opacity: 1,
        duration: 1,
        ease: "back.out",
        stagger: 0.02,
      });
    }
  }, []);

  return (
    <>
      {/* Hero Layout 2 Section Start */}
      <div className='CommonPages main-banner'>
        <section
          className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300"
          data-image-src="assets/img/photos/bg16.png"
        >
          <div id='home' className="hero-layout2 hero-video pb-0">
            {/* <div className="hero-bg-video">
              <div
                id="herovideo"
                className="player"
                data-property="{videoURL:'TzfnlPxCZv0',containment:'.hero-video', showControls:false, autoPlay:true, loop:true, vol:0, mute:false, startAt:0,  stopAt:296, opacity:1, addRaster:true, quality:'large', optimizeDisplay:true}"
              />
            </div> */}
            <div className="container" style={contentStyle}>
              <div className="row">
                <div className="col-lg-12">
                  {/* Hero Content Start */}
                  <div className="hero-content">
                    {/* Section Title Start */}
                    <div className="section-title subpage-header-box">
                      <h3 className="wow fadeInUp">Premier Dental Academy</h3>
                      <h1 className="text-anime-style-3" style={{ fontWeight: '400' }} ref={elementRef}>
                        Privacy Policy
                      </h1>
                      <ol className="breadcrumb wow fadeInUp">
                        <li>
                          <a href="index-2.html">Home</a>
                        </li>
                        <li>Privacy Policy</li>
                      </ol>
                    </div>
                    {/* Section Title End */}
                  </div>
                  {/* Hero Content Start */}
                </div>
              </div>
            </div>

            {/* /.container */}
            <div className="overflow-hidden">
              <div className="divider  mx-n2" style={{ color: '#FFF' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 60">
                  <path fill="currentColor" d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z" />
                </svg>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Banner;
