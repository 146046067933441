
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './home.css';

// import required modules
import { Mousewheel, Pagination } from 'swiper/modules';

function WhyChooseUs() {

    const elementRef = useRef(null);

    useEffect(() => {
        if (elementRef.current) {
            const element = elementRef.current;

            if (element.animation) {
                element.animation.progress(1).kill();
            }

            let split = new SplitType(element, { types: 'lines,words,chars' });
            gsap.set(element, { perspective: 400 });

            gsap.set(split.chars, {
                opacity: 0,
                x: "50",
            });

            element.animation = gsap.to(split.chars, {
                scrollTrigger: { trigger: element, start: "top 90%" },
                x: "0",
                y: "0",
                rotateX: "0",
                opacity: 1,
                duration: 1,
                ease: "back.out",
                stagger: 0.02,
            });
        }
    }, []);

    return (
        <>
            {/* Testimonial Section Satrt */}
            <div className="clients-testimonials ourclient whychooseus">
                <div className="container-fluid">
                    <div className="row section-row">
                        <div className="col-lg-12">
                            {/* Section Title Start */}
                            <div className="section-title text-center">
                                <h2 className="why" ref={elementRef}>Why Choose Us</h2>
                                {/* Hero Layout 3 Content Box Start*/}
                                <div className="hero-body mt-3 mb-0">
                                    <p>
                                        Our modern facilities are equipped with the latest technology and resources to support hands-on learning and practical skill development. From fully phantom head training room to advanced simulation centers, our facility provides students with the tools they need to succeed in today's rapidly evolving dental industry.
                                    </p>
                                </div>
                                {/* Hero Layout 3 Content Box End*/}
                            </div>
                            {/* Section Title End */}
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-md-12">
                            {/* Our Projects Layout 2 Start */}
                            <Swiper
                                direction={'vertical'}
                                slidesPerView={1}
                                spaceBetween={30}
                                mousewheel={{
                                    forceToAxis: true,
                                    sensitivity: 1,
                                    releaseOnEdges: true,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Mousewheel, Pagination]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="we-improving">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    {/* Improving Image Start */}
                                                    <div className="improving-img">
                                                        <figure className="image-anime ">
                                                            <img src="assets/images/slider-1-a.jpg" alt="" />
                                                        </figure>
                                                    </div>
                                                    {/* Improving Image End */}
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* Improving Content Start */}
                                                    <div className="improving-content">
                                                        {/* Section Title Start */}
                                                        <div className="section-title">
                                                            <h2 className="" >
                                                                Excellence in Education
                                                            </h2>
                                                        </div>
                                                        {/* Section Title End */}
                                                        {/* Improving Body Start */}
                                                        <div className="improving-body">
                                                            <p className="">
                                                                SAGE Academy is synonymous with excellence in dental education. Our commitment to delivering high-quality, comprehensive courses is unwavering, ensuring that students receive the knowledge and skills they need to excel in their practice. With a faculty comprised of industry experts and thought leaders, we provide an unparalleled learning experience that sets our graduates apart in the competitive field of dentistry.
                                                            </p>
                                                        </div>
                                                        {/* Improving Body End */}

                                                    </div>
                                                    {/* Improving Content End */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="we-improving">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    {/* Improving Image Start */}
                                                    <div className="improving-img">
                                                        <figure className="image-anime ">
                                                            <img src="assets/images/slider-1-b.jpg" alt="" />
                                                        </figure>
                                                    </div>
                                                    {/* Improving Image End */}
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* Improving Content Start */}
                                                    <div className="improving-content">
                                                        {/* Section Title Start */}
                                                        <div className="section-title">
                                                            <h2 className="">
                                                                Innovative Approach
                                                            </h2>
                                                        </div>
                                                        {/* Section Title End */}
                                                        {/* Improving Body Start */}
                                                        <div className="improving-body">
                                                            <p className="">
                                                                Innovation is at the heart of everything we do at SAGE Academy. We continuously strive to push the boundaries of dental education, incorporating the latest advancements in technology and teaching methodologies into our curriculum. From hands-on training in state-of-the-art facilities to interactive online learning platforms, we provide dynamic educational experiences that prepare students to thrive in an ever-evolving profession.
                                                            </p>
                                                        </div>
                                                        {/* Improving Body End */}

                                                    </div>
                                                    {/* Improving Content End */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="we-improving">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    {/* Improving Image Start */}
                                                    <div className="improving-img">
                                                        <figure className="image-anime ">
                                                            <img src="assets/images/photo1720793341.jpeg" alt="" />
                                                        </figure>
                                                    </div>
                                                    {/* Improving Image End */}
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* Improving Content Start */}
                                                    <div className="improving-content">
                                                        {/* Section Title Start */}
                                                        <div className="section-title">
                                                            <h2 className="">
                                                                Flexibility and Convenience
                                                            </h2>
                                                        </div>
                                                        {/* Section Title End */}
                                                        {/* Improving Body Start */}
                                                        <div className="improving-body">
                                                            <p className="">
                                                                We understand that flexibility is essential for busy dental professionals juggling work, family, and other commitments. That's why we offer flexible scheduling options for all our courses, allowing students to customize their learning experience to fit their individual needs and lifestyle. Whether you prefer in-person classes, online learning, or a combination of both, we have options to accommodate your schedule.
                                                            </p>
                                                        </div>
                                                        {/* Improving Body End */}

                                                    </div>
                                                    {/* Improving Content End */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="we-improving">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    {/* Improving Image Start */}
                                                    <div className="improving-img">
                                                        <figure className="image-anime ">
                                                            <img src="assets/images/compreh.png" alt="" />
                                                        </figure>
                                                    </div>
                                                    {/* Improving Image End */}
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* Improving Content Start */}
                                                    <div className="improving-content">
                                                        {/* Section Title Start */}
                                                        <div className="section-title">
                                                            <h2 className="">
                                                                Comprehensive Support
                                                            </h2>
                                                        </div>
                                                        {/* Section Title End */}
                                                        {/* Improving Body Start */}
                                                        <div className="improving-body">
                                                            <p className="">
                                                                At SAGE Academy, we believe that success extends beyond the classroom. That's why we provide comprehensive support to our students every step of the way. From personalized academic advising to career counseling and job placement assistance, we are committed to helping our graduates achieve their professional goals and thrive in their careers.

                                                            </p>
                                                        </div>
                                                        {/* Improving Body End */}

                                                    </div>
                                                    {/* Improving Content End */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="we-improving">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    {/* Improving Image Start */}
                                                    <div className="improving-img">
                                                        <figure className="image-anime ">
                                                            <img src="assets/images/community.jpeg" alt="" />
                                                        </figure>
                                                    </div>
                                                    {/* Improving Image End */}
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* Improving Content Start */}
                                                    <div className="improving-content">
                                                        {/* Section Title Start */}
                                                        <div className="section-title">
                                                            <h2 className="">
                                                                Community and Networking
                                                            </h2>
                                                        </div>
                                                        {/* Section Title End */}
                                                        {/* Improving Body Start */}
                                                        <div className="improving-body">
                                                            <p className="">
                                                                Joining SAGE Academy means becoming part of a vibrant community of dental professionals dedicated to excellence and collaboration. Our alumni network spans the globe, providing students with valuable networking opportunities, mentorship, and ongoing support throughout their careers. Whether you're a recent graduate or a seasoned professional, you'll find a supportive community at SAGE ready to help you succeed.
                                                            </p>
                                                        </div>
                                                        {/* Improving Body End */}

                                                    </div>
                                                    {/* Improving Content End */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="we-improving">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    {/* Improving Image Start */}
                                                    <div className="improving-img">
                                                        <figure className="image-anime ">
                                                            <img src="assets/images/why-choose-1.JPG" alt="" />
                                                        </figure>
                                                    </div>
                                                    {/* Improving Image End */}
                                                </div>
                                                <div className="col-lg-6">
                                                    {/* Improving Content Start */}
                                                    <div className="improving-content">
                                                        {/* Section Title Start */}
                                                        <div className="section-title">
                                                            <h2 className="">
                                                                Commitment to Continuous Improvement
                                                            </h2>
                                                        </div>
                                                        {/* Section Title End */}
                                                        {/* Improving Body Start */}
                                                        <div className="improving-body">
                                                            <p className="">
                                                                At SAGE Academy, we are committed to continuous improvement and innovation in everything we do. We regularly review and update our curriculum to reflect the latest advancements in dentistry and ensure that our courses remain relevant and impactful. By choosing SAGE, you can trust that you're receiving the most up-to-date education and training available in the field.
                                                            </p>
                                                        </div>
                                                        {/* Improving Body End */}

                                                    </div>
                                                    {/* Improving Content End */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            {/* Our Projects Layout 2 End */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial Section End */}
        </>

    )
}

export default WhyChooseUs