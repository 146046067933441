import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';
import { Link } from 'react-router-dom'
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const handleLinkClick = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};


function Whatweprovide() {
  return (
    <div className="hero-layout3 hero-slider whatweprovide">
      <div className="hero-slider-layout3">
        <Swiper
          modules={[Pagination, Navigation]}
          slidesPerView={1}
          speed={2000}
          spaceBetween={0}
          loop={true}
          autoplay={{ delay: 6000 }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
        >
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/provide-1.png" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 pl-5 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>What we provide</h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>
                          At SAGE Academy, we offer a comprehensive range of dentistry courses designed to meet the diverse needs of dental professionals at every stage of their careers. Our commitment to excellence extends beyond traditional education, as we strive to provide dynamic learning experiences that inspire growth, innovation, and professional advancement.
                        </p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                                            <div className="hero-image-box">
                                                <div className="rotate-circle" />
                                                <figure>
                                                    <img src="assets/images/improving-img.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </div> */}
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              <div id='whyus'></div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/provide-2.png" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>
                          Flexible Scheduling
                        </h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>
                          We understand that balancing professional commitments with educational pursuits can be challenging. Hence, we offer flexible scheduling options for all our courses, allowing busy dental professionals to tailor their learning experiences to fit their individual needs. Whether you're looking to enroll in a short-term workshop or a more extensive program, our flexible schedules ensure that you can pursue your educational goals without sacrificing your professional responsibilities.
                        </p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                          <div className="hero-image-box">
                            <div className="rotate-circle" />
                            <figure>
                              <img src="assets/images/home-contact-form-bg.jpg" alt="" />
                            </figure>
                          </div>
                        </div> */}
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/slider-1-b.jpg" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>
                          Comprehensive Curriculum
                        </h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                                            <div className="hero-image-box">
                                                <div className="rotate-circle" />
                                                <figure>
                                                    <img src="assets/images/cultuer-10.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </div> */}
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>
                          Our courses cover a wide range of topics and specialties within the field of dentistry, ensuring that participants receive a well-rounded education that prepares them for success in their chosen area of practice. From foundational courses in dental anatomy and radiography to advanced workshops in implantology and cosmetic dentistry, our curriculum is designed to equip students with the knowledge and skills needed to excel in today's competitive dental landscape.
                        </p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/provide-3.JPG" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>
                          Expert Instruction
                        </h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                                            <div className="hero-image-box">
                                                <div className="rotate-circle" />
                                                <figure>
                                                    <img src="assets/images/cultuer-9.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </div> */}
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>
                          At SAGE Academy, we believe that the quality of education is directly linked to the expertise of our instructors. That's why we handpick industry-leading professionals with extensive experience and a passion for teaching to lead our courses. Our instructors bring a wealth of real-world knowledge and insights to the classroom, providing students with invaluable guidance and mentorship as they navigate their educational journey.
                        </p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/slider-1-d.jpeg" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>
                          State-of-the-Art Facilities
                        </h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>
                          Our modern facilities are equipped with the latest technology and resources to support hands-on learning and practical skill development. From fully phantom head training room to advanced simulation centers, our facility provides students with the tools they need to succeed in today's rapidly evolving dental industry.
                        </p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                                            <div className="hero-image-box">
                                                <div className="rotate-circle" />
                                                <figure>
                                                    <img src="assets/images/cultuer-8.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </div> */}
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-slide">
              {/* Slider Image Start */}
              <div className="hero-slider-image">
                <img src="assets/images/provide-4.JPG" alt="" />
              </div>
              {/* Slider Image End */}
              {/* Slider Content Start */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 provide-heading">
                    {/* Hero Layout 3 Content Box Start*/}
                    <div className="hero-content headerhero">
                      <div className="section-title">
                        <h3>What we provide</h3>
                        <h1>
                          Continuing Support
                        </h1>
                      </div>
                    </div>
                    {/* Hero Layout 3 Content Box End*/}
                  </div>
                  <div className="col-md-6 provide-content">
                    <div className="hero-content">
                      <div className="hero-body">
                        <p>
                          Our commitment to our students extends far beyond the classroom. We provide ongoing support and guidance to help students navigate their educational journey and achieve their professional goals. Whether you need assistance with course selection, career advice, or additional resources, our dedicated team is here to help every step of the way.                                                        </p>
                      </div>
                    </div>
                    {/* Hero Layout 3 Image Start */}
                    {/* <div className="hero-image">
                                            <div className="hero-image-box">
                                                <div className="rotate-circle" />
                                                <figure>
                                                    <img src="assets/images/cultuer-9.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </div> */}
                    {/* Hero Layout 3 Image End */}
                  </div>
                </div>
              </div>
              {/* Slider Content End */}
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}

export default Whatweprovide