import React, { useEffect, useState } from "react";
import Loader from '../../../helper/Loader';
import { getRequest } from "../../../helper/axiosClient";
import { errorNotification } from "../../../helper/notification";
import Header from '../../commonPages/Header';
import { COURSE_LIST } from "../../constant/apiEndPoint";
import Banner from './Banner';
import Servicediffrent from './Servicediffrent';
import Upcomingcourse from './Upcomingcourse';
import Footer from "../../commonPages/Footer";

const OurCourses = () => {
  const [courseList, setCourseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCourseList = async () => {
    setIsLoading(true)
    try {
      const res = await getRequest(COURSE_LIST);
      if (res?.data?.status === 1) {
        setCourseList(res?.data?.data);
        // successNotification(res?.data?.message);
      }
      if (res.data.type === 2) {
        errorNotification(res.data.errormessage);
      }
    } catch (error) {
      console.error("error", error);
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCourseList()
  }, [])

  return (
    <>
      {/* Main content Start */}
      <div className="main-content">
        <Header />
        <Banner />
        {isLoading ? <Loader /> :
          <>
            <Upcomingcourse courseList={courseList} />
            <Servicediffrent courseList={courseList} />
          </>
        }
      </div>

      <Footer />
      {/* Main content End */}
    </>
  )
}

export default React.memo(OurCourses)

