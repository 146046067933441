import Cookies from 'js-cookie';
export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      Cookies.get("token")
        ? Cookies.get("token")
        : null;
  } catch (error) {
    user = null;
  }
  return user;
};
