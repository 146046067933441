import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import SplitType from 'split-type';

function OurClient() {

    const elementRef = useRef(null);

    useEffect(() => {
        if (elementRef.current) {
          const element = elementRef.current;
    
          if (element.animation) {
            element.animation.progress(1).kill();
          }
    
          let split = new SplitType(element, { types: 'lines,words,chars' });
          gsap.set(element, { perspective: 400 });
    
          gsap.set(split.chars, {
            opacity: 0,
            x: "50",
          });
    
          element.animation = gsap.to(split.chars, {
            scrollTrigger: { trigger: element, start: "top 90%" },
            x: "0",
            y: "0",
            rotateX: "0",
            opacity: 1,
            duration: 1,
            ease: "back.out",
            stagger: 0.02,
          });
        }
      }, []);
      
    return (
        <>
            {/* Testimonial Section Satrt */}
            <div className="clients-testimonials ourclient">
                <div className="container-fluid">
                    <div className="row section-row">
                        <div className="col-lg-12">
                            {/* Section Title Start */}
                            <div className="section-title text-center">
                                {/* <h3 className="wow fadeInUp">Our partners</h3> */}
                                <h2 className="text-anime-style-3" ref={elementRef}>Our partners</h2>
                            </div>
                            {/* Section Title End */}
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-3">
                                {/* Medical Service Item Start */}
                                <div className="medical-service-item wow fadeInUp first-medical-service-item">
                                    <div className="medical-service-content">
                                        {/* Icon Box Start */}
                                        <div className="icon-box">
                                            <img src="assets/images/client/Sage_Dental_Inverse.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                {/* Medical Service Item End */}
                            </div>
                            <div className="col-lg-3 col-md-3 col-md-4">
                                {/* Medical Service Item Start */}
                                <div className="medical-service-item wow fadeInUp">
                                    <div className="medical-service-content">
                                        {/* Icon Box Start */}
                                        <div className="icon-box">
                                            <img src="assets/images/client/West_London_Dental_Inverse.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                {/* Medical Service Item End */}
                            </div>
                            <div className="col-lg-3 col-md-3 col-md-4">
                                {/* Medical Service Item Start */}
                                <div className="medical-service-item wow fadeInUp">
                                    <div className="medical-service-content">
                                        {/* Icon Box Start */}
                                        <div className="icon-box">
                                            <img src="assets/images/client/BAC-logo.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                {/* Medical Service Item End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial Section End */}
        </>

    )
}

export default OurClient