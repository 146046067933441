import React, { useState } from 'react';
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";

function ConfidentialityPolicy() {

    const [key, setKey] = useState('home');
    return (
        <div className="page-project-single">
            <div className="container">
                <div className="project-entry">
                    <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
                        <TabList>
                            <Tab tabFor="vertical-tab-one"><h1 className='policytabs'>Confidentiality Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-two"><h1 className='policytabs'>Data Protection Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-three"><h1 className='policytabs'>Social Media Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-four"><h1 className='policytabs'>Refund Policy</h1></Tab>
                        </TabList>
                        <TabPanel tabId="vertical-tab-one">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Confidentiality Policy</h1>
                                <h1>Purpose of Document</h1>
                                <p>The purpose of this document is to ensure all staff members at the College are aware of their legal duty to maintain conﬁdentiality, to inform them of the processes in place to protect personal information, and to provide guidance on disclosure obligations.</p>

                                <h2>Introduction</h2>
                                <p>Everyone working for the college or elsewhere within the business is under a legal duty to keep student’s personal information conﬁdential. Students who believe their conﬁdence has been breached may make a complaint to the college, and they could take legal action or report it to the ICO. In the case of a registered dental professional tutor, the student could also make a complaint to the General Dental Council, which, in worst-case scenarios, may end in erasure from the GDC register.</p>
                                <p>This policy is concerned with protecting personal student information, although its content would apply equally to staff personal information or business-sensitive information.</p>
                                <p>Personal information is data in any form (paper, electronic, tape, verbal, etc.) from which a living individual could be identiﬁed including:</p>
                                <ul>
                                    <li>Name, age, address, and personal circumstances, as well as sensitive personal information like race, health, sexuality, etc.</li>
                                    <li>Information regarding appointments Information regarding medical histories</li>
                                    <li>Information regarding ﬁnances, including any bad debts.</li>
                                </ul>
                                <p>Although the Data Protection Act 2018 is only relevant to the personal information of living individuals, this code also covers information about students. This policy applies to all staff, including permanent, temporary, and locum staff members.</p>

                                <h2>Conﬁdentiality</h2>
                                <p>Under the Data Protection Act 2018 and UK GDPR, dental colleges must keep personal data about their student safe and secure and to ensure it is only accessed by persons who need to see it for the purposes of providing safe, effective education.</p>
                                <p>Registered dental professionals and tutors have an ethical and legal duty to keep all student information conﬁdential.</p>

                                <h2>Caldicott Principles</h2>
                                <p>The Caldicott Principles are the guidelines for ensuring people's information is kept conﬁdential and used or shared appropriately within a healthcare setting.</p>
                                <p>All NHS organizations must have an appointed Caldicott Guardian. This won't apply to most dental colleges, although there should be someone within the college who is responsible for ensuring student information is kept conﬁdential and shared appropriately when required.</p>
                                <p>The Caldicott Principles</p>
                                <ul>
                                    <li>Principle 1: Justify the purpose for using the conﬁdential information.</li>
                                    <li>Principle 2: Use conﬁdential information only when it is necessary</li>
                                    <li>Principle 3: Use the minimum necessary conﬁdential information</li>
                                    <li>Principle 4: Access to conﬁdential information should be on a strict need-to-know basis</li>
                                    <li>Principle 5: Everyone with access to conﬁdential information should be aware of their responsibilities</li>
                                    <li>Principle 6: Comply with the law</li>
                                    <li>Principle 7: The duty to share information for individual education is as important as the duty to protect students' conﬁdentiality</li>
                                </ul>

                                <h2>Disclosing Student Information</h2>
                                <p>Personal information relating to a student should only be shared with third parties where the student has given consent or in exceptional circumstances (GDC Standards 4.3).</p>
                                <p>Examples of where information may be shared without consent include:</p>
                                <ul>
                                    <li>In safeguarding concerns where it is not possible to gain consent and a referral needs to be made to the local authority or the police.</li>
                                    <li>Where information has been ordered by a court or by a coroner, where a court order has requested information, only the minimum amount of information should be disclosed.</li>
                                </ul>
                                <p>Before disclosing information to third parties where consent has not been obtained, you are advised to contact your indemnity provider.</p>

                                <h2>The Importance of Conﬁdentiality</h2>
                                <p>The relationship between the tutor and student is based on the understanding that any information revealed by the student to the tutor will not be divulged without the student’s consent. Students have the right to privacy and they must give to organization full information on their state of educational history to ensure that the education is carried out effectively. The intensely personal nature of health information means that many student would be reluctant to provide the collage with information if they felt the information would be passed on.</p>
                                <p>Education must be taken to ensure that conﬁdentiality is never breached, even to the most minor degree, in the use of social media or websites (GDC Standards 4.2.3).</p>

                                <h2>Recognize Your Obligations</h2>
                                <p>A duty of conﬁdence arises out of the common law duty of conﬁdence, employment contracts, and for registered dental professionals, it is part of your professional obligations. Breaches of conﬁdence and inappropriate use of records or computer systems are serious matters which could result in disciplinary proceedings, dismissal, and possibly legal prosecution.</p>
                                <p>So, make sure you do not:</p>
                                <ul>
                                    <li>Put personal information at risk of unauthorized access.</li>
                                    <li>Knowingly misuse any personal information or allow others to do so.</li>
                                    <li>Access records or information that you have no legitimate reason to look at. This includes records and information about your family, friends, neighbors, and acquaintances.</li>
                                </ul>

                                <h2>GDC standards guidance</h2>
                                <p>Dental Educational professionals have an ethical and legal duty to ensure they are familiar with and comply with the GDC’s standards and guidance. All team members must also follow this guidance and ensure that they maintain student conﬁdentiality. Copies of this publication in full are available as PDF downloads from the GDC’s website at www.gdc-uk.org</p>
                                <p>4.2	    Protect the conﬁdentiality of students’ information and only use it for the purpose for which it was given.</p>
                                <p>4.2.1	Conﬁdentiality is central to the relationship and trust between you and your student. You must keep student information conﬁdential. This applies to all the information about students that you have  learned in your professional role including personal details, student educational history, what education they are having, and how much it costs.</p>
                                <p>4.2.3    You must not post any information or comments about students on social networking or blogging sites. If you use professional social media to discuss anonymized cases for the purpose of    discussing best education you must be careful that the student or students cannot be identiﬁed.</p>

                                <p>The latest approved version of this document supersedes all other versions, upon receipt of the latest approved version all other versions should be destroyed unless speciﬁcally stated that previous version(s) are to remain extant. If in any doubt, please contact the document Author.</p>
                                <p>Approved By: Dr. Jasmin Thoria, Dr. Kuldipsinh Gohil, Sukhdeep Kaeda Date Published: 28/06/2024</p>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-two">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Data Protection Policy</h1>
                                <p>This Policy is specifically for Dental colleges in England and Wales and is a mutual agreement between the college entity and the staff (comprising of both employees and non-employed staff members)</p>
                                <p>Please be aware that this Policy and Annexures are generic and do not negate the necessity for specific advice and a thorough review of the document to precisely reﬂect your circumstances. This is strongly recommended:</p>
                                <p>to use the GDPR Risk assessment link to assess the suitability of your Policy and carry out a Data Protection Impact Assessment (DPIA) as set out in clause 19 before implementing this Policy.</p>
                                <h2>Contents</h2>
                                <ol className='listol'>
                                    <li>Interpretation</li>
                                    <li>Introduction</li>
                                    <li>Scope of Policy and when to seek advice on data protection compliance</li>
                                    <li>Personal data protection principles</li>
                                    <li>Lawfulness, fairness, and transparency</li>
                                    <li>Consent</li>
                                    <li>Transparency (notifying Data Subjects)</li>
                                    <li>Purpose limitation</li>
                                    <li>Data minimisation</li>
                                    <li>Accuracy</li>
                                    <li>Storage limitation</li>
                                    <li>Security integrity and confidentiality</li>
                                    <li>Reporting a Personal Data Breach</li>
                                    <li>Transfer limitation</li>
                                    <li>Data Subject's rights and requests</li>
                                    <li>Accountability</li>
                                    <li>Record keeping</li>
                                    <li>Training and audit</li>
                                    <li>Privacy by Design and Data Protection Impact Assessment (DPIA)</li>
                                    <li>Processing (including profiling) and Automated Decision-Making</li>
                                    <li>Direct marketing</li>
                                    <li>Sharing Personal Data</li>
                                    <li>Caldicott Principles</li>
                                    <li>Changes to this Data Protection Policy</li>
                                </ol>

                                <ol className='ollicountdot'>
                                    <li className='h2li'>
                                        <span>Interpretation</span>
                                        <ol>
                                            <li className='h3li'>
                                                Definitions:
                                                <div className='pContanier'>
                                                    <p><b>Automated Decision-Making (ADM):</b> when a decision is made based solely on Automated Processing (including profiling), which produces legal effects or significantly affects an individual. The UK GDPR prohibits Automated Decision-Making (unless certain conditions are met) but not Automated Processing.</p>
                                                    <p><b>Automated Processing:</b> any form of automated processing of Personal Data consisting of the use of Personal Data to evaluate certain personal aspects relating to an individual, in particular, to analyze or predict aspects concerning that individual's performance at work, economic situation, health, personal preferences, interests, reliability, behavior, location, or movements. Profiling is an example of automated processing, as are many uses of artificial intelligence (AI), which involves the processing of personal data.</p>
                                                    <p><b>Caldicott Principles:</b> 8 Principles used to ensure people’s information is kept confidential and used appropriately.</p>
                                                    <p><b>Company name:</b> [Sage Academy].</p>
                                                    <p><b>Company Personnel:</b> all employees, associates, and, without limitation, other self-employed staff, workers, contractors, agency workers, consultants, directors, members, and others.</p>
                                                    <p><b>Consent:</b> agreement which must be freely given, specific, informed, and be an unambiguous indication of the Data Subject's wishes by which they, by a statement or by a clear positive action, signify agreement to the Processing of Personal Data relating to them.</p>
                                                    <p><b>Controller:</b> the person or organization that determines when, why, and how to process Personal Data. It is responsible for establishing practices and policies in line with the UK GDPR. We are the Controller of all Personal Data relating to our Company Personnel and Personal Data used in our business for our own commercial purposes.</p>
                                                    <p><b>Criminal Convictions Data:</b> personal data relating to criminal convictions and offenses, including personal data relating to criminal allegations and proceedings.</p>
                                                    <p><b>Data Subject:</b> a living, identified, or identifiable individual about whom we hold Personal Data. Data Subjects may be nationals or residents of any country and may have legal rights regarding their Personal Data.</p>
                                                    <p><b>Data Privacy Impact Assessment (DPIA):</b> tools and assessments used to identify and reduce risks of a data processing activity. A DPIA can be carried out as part of Privacy by Design and should be conducted for all major system or business setups, as well as on adopting this Policy and any change programs involving the Processing of Personal Data.</p>
                                                    <p><b>Data Protection Officer (DPO):</b> either of the following:</p>
                                                    <ul>
                                                        <li>The person required to be appointed in specific circumstances under the UK GDPR; or</li>
                                                        <li>where wholly a college and a mandatory DPO have not been appointed, a Data Protection Lead is recommended to take responsibility for data protection compliance.</li>
                                                    </ul>
                                                    <p><b>Explicit Consent:</b> consent that requires a very clear and specific statement (that is, not just action).</p>
                                                    <p><b>UK GDPR:</b> the retained EU law version of the General Data Protection Regulation ((EU) 2016/679) as defined in the Data Protection Act 2018. Personal Data is subject to the legal safeguards specified in the UK GDPR.</p>
                                                    <p><b>Personal Data:</b> any information identifying a Data Subject or information relating to a Data Subject that we can identify (directly or indirectly) from that data alone or in combination with other identifiers we possess or can reasonably access. Personal Data includes Special Categories of Personal Data and Pseudonymized Personal Data but excludes anonymous data or data that has had the identity of an individual permanently removed. Personal data can be factual (for example, a name, email address, location, or date of birth) or an opinion about that person's actions or behavior. Personal Data specifically includes but is not limited to, medical records.</p>
                                                    <p><b>Personal Data Breach:</b> any act or omission that compromises the security, confidentiality, integrity, or availability of Personal Data or the physical, technical, administrative, or organizational safeguards that we or our third-party service providers put in place to protect it. The loss or unauthorized access, disclosure, or acquisition of Personal Data is a Personal Data Breach.</p>
                                                    <p><b>Privacy by Design:</b> implementing appropriate technical and organizational measures in an effective manner to ensure compliance with the UK GDPR.</p>
                                                    <p><b>Privacy Guidelines:</b> The company privacy and UK GDPR-related guidelines and policies provided to assist in interpreting and implementing this Data Protection Policy and Related Policies are available on the DCME compliance portal or on display at the practice.</p>
                                                    <p><b>Privacy Notices (also referred to as Fair Processing Notices) or Privacy Policies:</b> separate notices setting out information that may be provided to Data Subjects when the Company collects information about them. These notices may take the form of:</p>
                                                    <ul>
                                                        <li>general privacy statements applicable to a specific group of individuals (for example, employee privacy notices or the website privacy policy) or</li>
                                                        <li>stand-alone, one-time privacy statements covering Processing related to a specific purpose.</li>
                                                    </ul>
                                                    <p><b>Processing or Process:</b> any activity that involves the use of Personal Data. It includes obtaining, recording, or holding the data or carrying out any operation or set of operations on the data, including organizing, amending, retrieving, using, disclosing, erasing, or destroying it. Processing also includes transmitting or transferring Personal Data to third parties.</p>
                                                    <p><b>Pseudonymization or Pseudonymized:</b> replacing information that directly or indirectly identifies an individual with one or more artificial identifiers or pseudonyms so that the person to whom the data relates cannot be identified without the use of additional information which is meant to be kept separately and secure.</p>
                                                    <p><b>Related Policies:</b> the Company's policies, operating procedures, or processes related to this Data Protection Policy and designed to protect Personal Data and available from your line manager or the DPO.</p>
                                                    <p><b>Special Categories of Personal Data:</b> information revealing racial or ethnic origin, political opinions, religious or similar beliefs, trade union membership, physical or mental health conditions, sexual life, sexual orientation, biometric or genetic data. [The Company will treat the following types of data as if they are Special Categories of Personal Data.</p>

                                                </div>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Introduction</span>
                                        <ol className='pContanier'>
                                            <li>This Data Protection Policy sets out how (<strong>"we", "our", "us", and "the Company"</strong>) handle the Personal Data of our students, their parents or carers, customers, prospective students, suppliers, employees, workers, business contacts and other third parties.</li>
                                            <li>This Data Protection Policy applies to all Personal Data we Process regardless of the media on which that data is stored or whether it relates to past or present employees, workers, customers, clients or supplier contacts, shareholders, website users, or any other Data Subject.</li>
                                            <li>This Data Protection Policy applies to all Company Personnel (<strong>"you", "your"</strong>). You must read, understand, and comply with this Data Protection Policy when Processing Personal Data on our behalf and attend training on its requirements. Data protection is the responsibility of everyone within the Company, and this Data Protection Policy sets out what we expect from you when handling Personal Data to enable the Company to comply with applicable law. Your compliance with this Data Protection Policy is mandatory. Related Policies and Privacy Guidelines are available to help you interpret and act in accordance with this Data Protection Policy. You must also comply with all those Related Policies and Privacy Guidelines. Any breach of this Data Protection Policy may result in disciplinary action.</li>
                                            <li>Where you have a specific responsibility in connection with Processing, such as capturing Consent, reporting a Personal Data Breach or conducting a DPIA as referenced in this Data Protection Policy or otherwise, then you must comply with the Related Policies and Privacy Guidelines.</li>
                                            <li>This Data Protection Policy (together with Related Policies and Privacy Guidelines) is an internal document and cannot be shared with third parties, clients, or regulators without prior authorization from the DPO unless legally required to do so.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Scope of Policy and when to seek advice on data protection compliance.</span>
                                        <ol className='pContanier'>
                                            <li>We recognize that the correct and lawful education of Personal Data will maintain trust and confidence in the organization and will provide for successful business operations. Protecting the confidentiality and integrity of Personal Data is a critical responsibility we take seriously. The Company is exposed to potential fines of up to £17.5 million or 4% of total worldwide annual turnover, whichever is higher and depending on the breach, for failure to comply with the UK GDPR.</li>
                                            <li>All line managers and other responsible parties are responsible for ensuring all Company Personnel comply with this Data Protection Policy and must implement appropriate practices, processes, controls, and training to ensure compliance.</li>
                                            <li>The DPO oversees this Data Protection Policy and, as applicable, develops Related Policies and Privacy Guidelines. That post is held by Dr Jasmin Thoria, and they can be reached at [INSERT TELEPHONE NUMBER] and [EMAIL ADDRESS].</li>
                                            <li>Please contact the DPO with any questions about the operation of this Data Protection Policy or the UK GDPR or if you have any concerns that this Data Protection Policy is not being or has not been followed. In particular, you must always contact the DPO in the following circumstances:
                                                <ul>
                                                    <li>If you are unsure of the lawful basis on which you are relying to process Personal Data (including the legitimate interests used by the Company) (see paragraph 5.1).</li>
                                                    <li>If you need to rely on Consent or need to capture Explicit Consent (see paragraph 6).</li>
                                                    <li>If you need to draft Privacy Notices (see paragraph 7).</li>
                                                    <li>If you are unsure about the retention period for the Personal Data being Processed (see paragraph 11).</li>
                                                    <li>If you are unsure what security or other measures you need to implement to protect Personal Data (see paragraph 12.1).</li>
                                                    <li>If there has been a Personal Data Breach (paragraph 13).</li>
                                                    <li>If you are unsure on what basis to transfer Personal Data outside the UK (see paragraph 14).</li>
                                                    <li>If you need any assistance dealing with any rights invoked by a Data Subject (see paragraph 15).
                                                        <ul style={{ marginTop: '10px' }}>
                                                            <li>Whenever you are engaging in a significant new or change in Processing activity that is likely to require a DPIA (see paragraph 19) or plan to use Personal Data for purposes other than for which it was collected (see paragraph 8).</li>
                                                            <li>If you plan to undertake any activities involving Automated Processing, including profiling or Automated Decision-Making (see paragraph 20).</li>
                                                        </ul>
                                                    </li>
                                                    <li>If you need help complying with applicable law when carrying out direct marketing activities (see paragraph 21).
                                                        <ul>
                                                            <li>If you need help with any contracts or other areas in relation to sharing Personal Data with third parties (including our vendors) (see paragraph 22).</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Personal data protection principles</span>
                                        <ol className='pContanier'>
                                            <li>We adhere to the principles relating to the Processing of Personal Data set out in the UK GDPR, which require Personal Data to be:
                                                <ul>
                                                    <li>Processed lawfully, fairly and in a transparent manner (lawfulness, fairness, and transparency).</li>
                                                    <li>Collected only for specified, explicit and legitimate purposes (purpose limitation).</li>
                                                    <li>Adequate, relevant, and limited to what is necessary in relation to the purposes for which it is Processed (data minimization).</li>
                                                    <li>Accurate and, where necessary, kept up to date (accuracy).</li>
                                                    <li>Not kept in a form that permits the identification of Data Subjects for longer than is necessary for the purposes for which the data is Processed (storage limitation).</li>
                                                    <li>Processed in a manner that ensures its security using appropriate technical and organizational measures to protect against unauthorized or unlawful Processing and accidental loss, destruction, or damage (security, integrity, and confidentiality).</li>
                                                    <li>Not transferred to another country without appropriate safeguards in place (transfer limitation).</li>
                                                    <li>Made available to Data Subjects and allow Data Subjects to exercise certain rights in relation to their Personal Data (data subject's rights and requests).</li>
                                                </ul>
                                            </li>
                                            <li>
                                                We are responsible for and must be able to demonstrate compliance with the data protection principles listed above (accountability).
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Lawfulness, fairness, and transparency</span>
                                        <ol className='pContanier'>
                                            <li>
                                                Personal data must be processed lawfully, fairly, and transparently in relation to the Data Subject.
                                            </li>
                                            <li>
                                                You may only collect, Process, and share Personal Data fairly, lawfully, and for specified purposes. The UK GDPR restricts our actions regarding Personal Data to specified lawful purposes. These restrictions are not intended to prevent processing but to ensure that we process personal data fairly and without adversely affecting the data subject.
                                            </li>
                                            <li>The UK GDPR allows Processing for specific purposes, some of which are set out below:
                                                <ul>
                                                    <li>The Data Subject has given their Consent.</li>
                                                    <li>The processing is necessary for the performance of a contract with the Data Subject.</li>
                                                    <li>To meet our legal compliance obligations.</li>
                                                    <li>To protect the Data Subject's vital interests.</li>
                                                    <li>To pursue our legitimate interests (or those of a third party) for purposes where they are not overridden because the Processing prejudices the interests or fundamental rights and freedoms of Data Subjects. The purposes for which we process Personal Data for legitimate interests need to be set out in applicable Privacy Notices.</li>
                                                    <li>[OTHER UK GDPR PROCESSING GROUNDS].</li>
                                                </ul>
                                            </li>
                                            <li>You must identify and document the legal ground being relied on for each Processing activity [in accordance with the Company's guidelines on the Lawful Basis for Processing Personal Data, available or from the DPO.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Consent</span>
                                        <ol className='pContanier'>
                                            <li>A Controller must only process Personal Data on one or more of the lawful bases set out in the UK GDPR, which include Consent.</li>
                                            <li>A Data Subject consents to the Processing of their Personal Data if they clearly indicate agreement to the Processing. Consent requires affirmative action, so silence, pre-ticked boxes, or inactivity will not be sufficient to indicate consent. If consent is given in a document that deals with other matters, then consent must be kept separate from other matters.</li>
                                            <li>A Data Subject must be easily able to withdraw Consent to Processing at any time, and withdrawal must be promptly honored. Consent may need to be refreshed if you intend to process personal data for a different and incompatible purpose that was not disclosed when the Data Subject first consented.</li>
                                            <li>When processing Special Category Data or Criminal Convictions Data, we will usually rely on a legal basis for processing other than Explicit Consent or Consent if possible. Where Explicit Consent is relied on, you must issue a Privacy Notice to the Data Subject to capture Explicit Consent.</li>
                                            <li>You will need to evidence Consent and keep records of all Consent in accordance with Related Policies and Privacy Guidelines so that the Company can demonstrate compliance with Consent requirements.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Transparency (notifying Data Subjects)</span>
                                        <ol className='pContanier'>
                                            <li>The UK GDPR requires a Controller to provide detailed, specific information to a Data Subject depending on whether the information was collected directly from the Data Subject or from elsewhere. The information must be provided through an appropriate Privacy Notice, which must be concise, transparent, intelligible, easily accessible, and in clear and plain language so that a Data Subject can easily understand them.</li>
                                            <li>Whenever we collect Personal Data directly from a Data Subject, including for HR or employment purposes, we must provide the Data Subject with all the information required by the UK GDPR, including the identity of the Controller and DPO and how and why we will use, Process, disclose, protect and retain that Personal Data through a Privacy Notice which must be presented when the Data Subject first provides the Personal Data.</li>
                                            <li>When Personal Data is collected indirectly (for example, from a third party or publicly available source), we must provide the Data Subject with all the information required by the UK GDPR as soon as possible after collecting or receiving the data. We must also check that the third party collected the Personal Data in accordance with the UK GDPR and on a basis that considers our proposed Processing of that Personal Data.</li>
                                            <li>If you are collecting Personal Data from a Data Subject, directly or indirectly, then you must provide the Data Subject with a Privacy Notice in accordance with our Related Policies and Privacy Guidelines.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Purpose limitation</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must be collected only for specified, explicit and legitimate purposes. It must not be further Processed in any manner incompatible with those purposes.</li>
                                            <li>You cannot use Personal Data for new, different, or incompatible purposes from that disclosed when it was first obtained unless you have informed the Data Subject of the new purposes and they have Consented where necessary.</li>
                                            <li>If you want to use Personal Data for a new or different purpose from that for which it was obtained, you must first contact the DPO for advice on how to do this in compliance with both the law and this Data Protection Policy.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Data minimization</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must be adequate, relevant, and limited to what is necessary in relation to the purposes for which it is processed.</li>
                                            <li>You may only process personal data when performing your job duties that require it. You cannot Process Personal Data for any reason unrelated to your job duties.</li>
                                            <li>You may only collect Personal Data that you require for your job duties; do not collect excessive data. Ensure any Personal Data collected is adequate and relevant for the intended purposes.</li>
                                            <li>You must ensure that when Personal Data is no longer needed for specified purposes, it is deleted or anonymized in accordance with the Company's data retention guidelines.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Accuracy</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must be accurate and, where necessary, kept up to date. It must be corrected or deleted without delay when inaccurate.</li>
                                            <li>You must ensure that the Personal Data we use and hold is accurate, complete, kept up to date, and relevant to the purpose for which we collected it. You must check the accuracy of any Personal Data at the point of collection and regular intervals afterward. You must take all reasonable steps to destroy or amend inaccurate or out-of-date Personal Data.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Storage limitation</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must not be kept in an identifiable form for longer than is necessary for the purposes for which the data is processed.</li>
                                            <li>The Company will maintain retention policies and procedures to ensure Personal Data is deleted after an appropriate time unless a law requires that data be kept for a minimum time. [You must comply with the Company's Data Retention Policy.]</li>
                                            <li>You must not keep Personal Data in a form that permits the identification of the Data Subject for longer than needed for the legitimate business purpose or purposes for which we originally collected it, including for the purpose of satisfying any legal, accounting, or reporting requirements.</li>
                                            <li>You will take all reasonable steps to destroy or erase from our systems all Personal Data that we no longer require in accordance with all the Company's applicable records retention schedules and policies. This includes requiring third parties to delete that data where relevant.</li>
                                            <li>You will provide Data Subjects with information about the period for which data is stored and how that period is determined in any applicable Privacy Notice.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Security integrity and confidentiality</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must be secured by appropriate technical and organizational measures against unauthorized or unlawful Processing and against accidental loss, destruction, or damage.</li>
                                            <li>We will develop, implement, and maintain safeguards appropriate to our size, scope and business, our available resources, the amount of Personal Data that we own or maintain on behalf of others, and identified risks (including use of encryption and Pseudonymization where applicable). We will regularly evaluate and test the effectiveness of those safeguards to ensure the security of our Processing of Personal Data. You are responsible for protecting the Personal Data we hold. You must implement reasonable and appropriate security measures against unlawful or unauthorized Processing of Personal Data and against the accidental loss of, or damage to, Personal Data. You must exercise particular care in protecting Special Categories of Personal Data and Criminal Convictions Data from loss and unauthorized access, use or disclosure.</li>
                                            <li>You must follow all procedures and technologies we put in place to maintain the security of all Personal Data from the point of collection to the point of destruction. You may only transfer Personal Data to third-party service providers who agree to comply with the required policies and procedures and who agree to put adequate measures in place, as requested.</li>
                                            <li>You must maintain data security by protecting the confidentiality, integrity and availability of the Personal Data, defined as follows:
                                                <ul>
                                                    <li>Confidentiality: only people who have a need to know and are authorized to use the Personal Data can access it;</li>
                                                    <li>Integrity: Personal Data is accurate and suitable for the purpose for which it is processed;</li>
                                                    <li>Availability: Authorized users are able to access their personal data when they need it for authorized purposes.</li>
                                                </ul>
                                            </li>
                                            <li>You must comply with and not attempt to circumvent the administrative, physical and technical safeguards we implement and maintain in accordance with the UK GDPR and relevant standards to protect Personal Data.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Reporting a Personal Data Breach</span>
                                        <ol className='pContanier'>
                                            <li>The UK GDPR requires Controllers to notify any Personal Data Breach to the Information Commissioner and, in certain instances, the Data Subject.</li>
                                            <li>We have put in place procedures to deal with any suspected Personal Data Breach and will notify the Data Subject or any applicable regulator where we are legally required to do so.</li>
                                            <li>If you know or suspect that a Personal Data Breach has occurred, do not attempt to investigate the matter yourself. Immediately contact the person or team designated as the key point of contact for Personal Data Breaches or the DPO) You should preserve all evidence relating to the potential Personal Data Breach.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Transfer limitation</span>
                                        <ol className='pContanier'>
                                            <li>The UK GDPR restricts data transfers to countries outside the UK to ensure that the level of data protection afforded to individuals by the UK GDPR is not undermined. You transfer Personal Data originating in one country across borders when you transmit, send, view or access that data in or to a different country.</li>
                                            <li>You must comply with the Company's guidelines on cross-border data transfers.</li>
                                            <li>You may only transfer Personal Data outside the UK if one of the following conditions applies:
                                                <ul>
                                                    <li>The UK has issued regulations confirming that the country to which we transfer the Personal Data ensures an adequate level of protection for the Data Subject's rights and freedoms;</li>
                                                    <li>Appropriate safeguards are in place, such as binding corporate rules, standard contractual clauses approved for use in the UK, an approved code of conduct, or a certification mechanism, a copy of which can be obtained from the DPO;</li>
                                                    <li>The Data Subject has provided Explicit Consent to the proposed transfer after being informed of any potential risks;</li>
                                                    <li>The transfer is necessary for one of the other reasons set out in the UK GDPR, including:
                                                        <ul>
                                                            <li>The performance of a contract between us and the Data Subject;</li>
                                                            <li>Reasons of public interest;</li>
                                                            <li>To establish, exercise, or defend legal claims;</li>
                                                            <li>To protect the vital interests of the Data Subject where the Data Subject is physically or legally incapable of giving Consent; and</li>
                                                            <li>In some limited cases, for our legitimate interest.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Data Subject's rights and requests</span>
                                        <ol className='pContanier'>
                                            <li>A Data Subject has rights regarding how we handle their Personal Data. These include rights to:
                                                <ul>
                                                    <li>Withdraw Consent to Processing at any time;</li>
                                                    <li>Receive certain information about the Controller's Processing activities;</li>
                                                    <li>Request access to their Personal Data that we hold (including receiving a copy of their Personal Data);</li>
                                                    <li>Prevent our use of their Personal Data for direct marketing purposes;</li>
                                                    <li>Ask us to erase Personal Data if it is no longer necessary in relation to the purposes for which it was collected or Processed, to rectify inaccurate data, or to complete incomplete data;</li>
                                                    <li>Restrict Processing in specific circumstances;</li>
                                                    <li>Object to Processing which has been justified on the basis of our legitimate interests or in the public interest;</li>
                                                    <li>Request a copy of an agreement under which Personal Data is transferred outside of the UK;</li>
                                                    <li>Object to decisions based solely on Automated Processing, including profiling (ADM);</li>
                                                    <li>Prevent Processing that is likely to cause damage or distress to the Data Subject or anyone else;</li>
                                                    <li>Be notified of a Personal Data Breach which is likely to result in a high risk to their rights and freedoms;</li>
                                                    <li>Make a complaint to the supervisory authority;</li>
                                                    <li>In limited circumstances, receive or ask for their Personal Data to be transferred to a third party in a structured, commonly used and machine-readable format; and</li>
                                                    <li>[ANY OTHER RIGHTS YOU MAY BE REQUIRED TO PROVIDE UNDER COMPANY POLICIES].</li>
                                                </ul>
                                            </li>
                                            <li>You must verify the identity of an individual requesting data under any of the rights listed above (do not allow third-parties to persuade you into disclosing Personal Data without proper authorization).</li>
                                            <li>You must immediately forward any Data Subject request you receive to the DPO, and they must comply with the Company's Response procedures for data subject requests.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Accountability</span>
                                        <ol className='pContanier'>
                                            <li>The Controller must implement appropriate technical and organizational measures effectively to ensure compliance with data protection principles. The Controller is responsible for and must be able to demonstrate compliance with the data protection principles.</li>
                                            <li>The Company must have adequate resources and controls in place to ensure and document UK GDPR compliance, including:
                                                <ul>
                                                    <li>Appointing a suitably qualified DPO (where necessary) and an executive accountable for data privacy;</li>
                                                    <li>Implementing Privacy by Design when Processing Personal Data and completing DPIAs where Processing presents a high risk to the rights and freedoms of Data Subjects;</li>
                                                    <li>Integrating data protection into internal documents, including this Data Protection Policy, Related Policies, Privacy Guidelines or Privacy Notices;</li>
                                                    <li>Regularly training Company Personnel on the UK GDPR, this Data Protection Policy, Related Policies and Privacy Guidelines, and data protection matters including, for example, a Data Subject's rights, Consent, legal basis, DPIA, and Personal Data Breaches. The Company must maintain a record of training attendance by Company Personnel; and</li>
                                                    <li>Regularly test the privacy measures implemented and conduct periodic reviews and audits to assess compliance, including using the results of testing to demonstrate compliance improvement efforts.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Record keeping</span>
                                        <ol className='pContanier'>
                                            <li>The UK GDPR requires us to keep full and accurate records of all our data Processing activities.</li>
                                            <li>You must keep and maintain accurate corporate records reflecting our Processing, including records of Data Subjects' Consents and procedures for obtaining Consents in accordance with the Company's record-keeping guidelines.</li>
                                            <li>These records should include, at a minimum:
                                                <ul>
                                                    <li>the name and contact details of the Controller and the DPO, and</li>
                                                    <li>clear descriptions of:
                                                        <ul>
                                                            <li>the Personal Data types;</li>
                                                            <li>the Data Subject types;</li>
                                                            <li>the Processing activities;</li>
                                                            <li>the Processing purposes;</li>
                                                            <li>the third-party recipients of the Personal Data;</li>
                                                            <li>the Personal Data storage locations;</li>
                                                            <li>the Personal Data transfers;</li>
                                                            <li>the Personal Data's retention period; and</li>
                                                            <li>the security measures in place.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>To create the records, data maps should be created, which should include the details set out above together with appropriate data flows.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Training and audit</span>
                                        <ol className='pContanier'>
                                            <li>We are required to ensure all Company Personnel have undergone adequate training to enable them to comply with data privacy laws. We must also regularly test our systems and processes to assess compliance.</li>
                                            <li>You must undergo all mandatory data privacy-related training and ensure your team undergo similar training [per the Company's mandatory training guidelines].</li>
                                            <li>You must regularly review all the systems and processes under your control to ensure they comply with this Data Protection Policy and check that adequate governance controls and resources are in place to ensure proper use and protection of Personal Data.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Privacy by Design and Data Protection Impact Assessment (DPIA)</span>
                                        <ol className='pContanier'>
                                            <li>We are required to implement Privacy by Design measures when Processing Personal Data by implementing appropriate technical and organizational measures (like Pseudonymization) effectively to ensure compliance with data privacy principles.</li>
                                            <li>You must assess what Privacy by Design measures can be implemented on all programs, systems, or processes that Process Personal Data by taking into account the following:
                                                <ul>
                                                    <li>The state of the art.</li>
                                                    <li>The cost of implementation.</li>
                                                    <li>The nature, scope, context, and purposes of Processing.</li>
                                                    <li>The risks of varying likelihood and severity for rights and freedoms of the Data Subject posed by the Processing.</li>
                                                </ul>
                                            </li>
                                            <li>The Controller must also regularly and not less than annually conduct a DPIA with respect to high-risk Processing.</li>
                                            <li>You should conduct a DPIA (and discuss your findings with the DPO) annually and when implementing a college set-up and before implementing this Policy and any major system or business change programs involving the Processing of Personal Data, including:
                                                <ul>
                                                    <li>Use of new technologies (programs, systems, or processes, including the use of AI) or changing technologies (programs, systems or processes).</li>
                                                    <li>Automated Processing, including profiling and ADM.</li>
                                                    <li>Large-scale Processing of Special Categories of Personal Data or Criminal Convictions Data.</li>
                                                    <li>Large-scale, systematic monitoring of a publicly accessible area.</li>
                                                </ul>
                                            </li>
                                            <li>A DPIA must include:
                                                <ul>
                                                    <li>A description of the Processing, its purposes and the Controller's legitimate interests if appropriate.</li>
                                                    <li>An assessment of the necessity and proportionality of the Processing in relation to its purpose.</li>
                                                    <li>An assessment of the risk to individuals.</li>
                                                    <li>The risk mitigation measures are in place, and compliance is demonstrated.</li>
                                                </ul>
                                            </li>
                                            <li>You must comply with the Company's guidelines on DPIA and Privacy by Design.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Processing (including profiling) and Automated Decision-Making</span>
                                        <ol className='pContanier'>
                                            <span><b>[This section can be removed if no automated decision-making is used]</b></span>
                                            <li className='mt-2'>Generally, ADM is prohibited when a decision has a legal or similar significant effect on an individual unless:
                                                <ul>
                                                    <li>a Data Subject has Explicitly Consented.</li>
                                                    <li>the Processing is authorized by law, or</li>
                                                    <li>the Processing is necessary for the performance of or entering a contract.</li>
                                                </ul>
                                            </li>
                                            <li>If certain types of Special Categories of Personal Data or Criminal Convictions Data are being processed, then grounds (b) or (c) will not be allowed. However, the Special Categories of Personal Data and Criminal Convictions Data can be processed where necessary (unless less intrusive means can be used) for substantial public interest, like fraud prevention.</li>
                                            <li>If a decision is to be based solely on Automated Processing (including profiling), then the Data Subject must be informed when you first communicate with them of their right to object. This right must be explicitly brought to their attention and presented clearly and separately from other information. Further, suitable measures must be implemented to safeguard the Data Subject's rights, freedoms, and legitimate interests.</li>
                                            <li>We must also inform the Data Subject of the logic involved in the decision-making or profiling, the significance, and the envisaged consequences, and give the Data Subject the right to request human intervention, express their point of view, or challenge the decision.</li>
                                            <li>A DPIA must be carried out before any Automated Processing (including profiling) or ADM activities are undertaken.</li>
                                            <li>Where you are involved in any data Processing activity that involves profiling or ADM, you must consult with your DPO.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Direct marketing</span>
                                        <ol className='pContanier'>
                                            <li>We are subject to certain rules and privacy laws when engaging in direct marketing to our customers and prospective customers (for example, when sending marketing emails or making telephone sales calls).</li>
                                            <li>For example, in a business-to-consumer context, a Data Subject's prior consent is generally required for direct electronic marketing (for example, by email, text, or automated calls). The limited exception for existing customers, known as "soft opt-in", allows an organization to send marketing texts or emails without consent if it:
                                                <ul>
                                                    <li>Has obtained contact details during a sale to that person.</li>
                                                    <li>Is marketing similar products or services?</li>
                                                    <li>Gave the person an opportunity to opt out of marketing when first collecting the details and in every subsequent marketing message.</li>
                                                </ul>
                                            </li>
                                            <li>The right to object to direct marketing must be explicitly offered to the Data Subject in an intelligible manner so that it is clearly distinguishable from other information.</li>
                                            <li>A Data Subject's objection to direct marketing must always be promptly honored. If customers or patients opt out of marketing at any time, their details should be suppressed as soon as possible. Suppression involves retaining just enough information to ensure that marketing preferences are respected in the future.</li>
                                            <li>You must comply with the Company's guidelines on direct marketing to customers and patients, and you should consult your DPO if you are unsure of how to comply with either the Company's guidelines or the law.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Sharing Personal Data</span>
                                        <ol className='pContanier'>
                                            <li>Generally, we are not allowed to share Personal Data with third parties unless certain safeguards and contractual arrangements have been put in place.</li>
                                            <li>You must comply with the Company's data-sharing guidelines with third parties.</li>
                                            <li>If the Practice is carrying out NHS dentistry, then please note the Guidelines at NHS England</li>
                                            <li>Then the Company shall review the Data Protection requirements (and compatibility with this Policy) set out at www.digital.nhs.uk, which will change from time to time and is under our control, and a risk assessment shall be carried out regularly by the Company by the DPO at the following web site (subject to change) https://www.dsptoolkit.nhs.uk. The Company shall conduct an online self-assessment against the National Data Guardian’s 10 data security standards. Any failures in reaching those standards shall be immediately actioned by the Company and DPO</li>
                                            <li>You may only share the Personal Data we hold with another employee, agent or representative of our group (which includes our subsidiaries and our ultimate holding company along with its subsidiaries) if the recipient has a job-related need to know the information and the transfer complies with any applicable cross-border transfer restrictions.</li>
                                            <li>You may only share the Personal Data we hold with third parties, such as our service providers, if:
                                                <ul>
                                                    <li>they have a need to know the information for the purposes of providing the contracted services.</li>
                                                    <li>sharing the Personal Data complies with the Privacy Notice provided to the Data Subject and, if required, the Data Subject's Consent has been obtained.</li>
                                                    <li>the third party has agreed to comply with the required data security standards, policies and procedures and put adequate security measures in place.</li>
                                                    <li>the transfer complies with any applicable cross-border transfer restrictions and</li>
                                                    <li>a fully executed written contract that contains UK GDPR-approved third-party clauses has been obtained.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Caldicott Principles</span>
                                        <ol className='pContanier'>
                                            <li>We and You follow the eight Caldicott principles for handling patient-identifiable information.
                                                <ul>
                                                    <li>Justify the purpose(s) of every proposed use or transfer</li>
                                                    <li>Don't use it unless it is necessary, and</li>
                                                    <li>Use the minimum necessary</li>
                                                    <li>Access to it is on a strict need-to-know basis</li>
                                                    <li>Everyone with access to it is aware of their responsibilities and</li>
                                                    <li>Understand and comply with the law</li>
                                                    <li>The Duty to share information can be as important as the duty to protect patient confidentiality</li>
                                                    <li>Inform patients and service users about how their confidential information is used</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Changes to this Data Protection Policy</span>
                                        <ol className='pContanier'>
                                            <li>We keep this Data Protection Policy under regular review.</li>
                                            <li>The latest approved version of this document supersedes all other versions, upon receipt of the latest approved version all other versions should be destroyed, unless specifically stated that previous version(s) are to remain extant. If in any doubt, please contact the document Author.</li>
                                            <li>Approved By: Dr. Jasmin Thoria, Dr. Kuldipsinh Gohil, Sukhdeep Kaeda Date Published: 28/06/2024</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-three">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Social Media Policy</h1>
                                <h2>Contents</h2>
                                <ol className='listol'>
                                    <li>About this policy</li>
                                    <li>Who does this policy apply to?</li>
                                    <li>Who is responsible for this policy</li>
                                    <li>Compliance with related policies and agreements</li>
                                    <li>Personal use of social media</li>
                                    <li>Prohibited use</li>
                                    <li>Business use of social media</li>
                                    <li>Guidelines for responsible use of social media</li>
                                    <li>Monitoring</li>
                                    <li>Recruitment</li>
                                    <li>Breach of this policy</li>
                                </ol>
                                <ol className='ollicountdot'>
                                    <li className='h2li'>
                                        <span>About this policy</span>
                                        <ol className='pContanier'>
                                            <li>This policy aims to minimize the risks to our business through social media. This policy applies to the use of all forms of social media, including all social networking sites, internet postings, and blogs. It applies to using social media for business and personal purposes that may affect our business in any way.</li>
                                            <li>This policy does not form part of any employment or other contract to provide services, and we may amend it at any time.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>To Whom does this policy apply?</span>
                                        <ol className='pContanier'>
                                            <li>This policy applies to all employees, self-employed team members, contractors, casual workers, agency workers, volunteers, and interns.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Who is responsible for this policy?</span>
                                        <ol className='pContanier'>
                                            <li>The Company has delegated responsibility for overseeing its implementation to Sukhdeep Kaeda (PM). Questions about the content of this policy or suggestions for change should be reported to the PM.</li>
                                            <li>You should ask the PM any questions you may have about the day-to-day application of this policy (including reporting the misuse of social media).</li>
                                            <li>This policy is reviewed annually by the PM.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Compliance with related policies and agreements</span>
                                        <ol className='pContanier'>
                                            <li>You should never use social media in a way that breaches any of our other policies. If an internet post breaches our policies in another forum, it would also breach them online. For example, you are prohibited from using social media to:
                                                <ul>
                                                    <li>Breach our IT and Communications Systems Policy;</li>
                                                    <li>Breach any obligations we may have with respect to the rules of relevant regulatory bodies;</li>
                                                    <li>Breach any obligations contained in those policies relating to confidentiality;</li>
                                                    <li>Breach our disciplinary procedures;</li>
                                                    <li>Harass or bully other staff in any way OR breach our bullying and harassment policy within the Employee Handbook.</li>
                                                    <li>Unlawfully discriminate against other staff or third parties OR breach our Equality, Diversity & Human Rights policy within the Employee Handbook;</li>
                                                    <li>Breach our Data Protection Policy (for example, you should never disclose personal information about a colleague online), or</li>
                                                    <li>Breach any other laws or regulatory requirements.</li>
                                                </ul>
                                            </li>
                                            <li>You should never provide references for other individuals on social or professional networking sites. These positive and negative references can be attributed to the organization and create legal liability for both the author of the reference and the organization.</li>
                                            <li>If you breach any of the above policies, you will be subject to disciplinary action up to and including termination of employment.</li>
                                        </ol>
                                    </li>

                                    <li className='h2li'>
                                        <span>Personal use of social media</span>
                                        <ol className='pContanier'>
                                            <li>Personal use of social media is never permitted during working hours or by means of our computers, networks, and other IT resources and communications systems.</li>
                                        </ol>
                                    </li>

                                    <li className='h2li'>
                                        <span>Prohibited use</span>
                                        <ol className='pContanier'>
                                            <li>You must avoid making social media communications that could indirectly damage our business interests or reputation.</li>
                                            <li>You must not use social media to:
                                                <ul>
                                                    <li>Defame or disparage us, our staff, or any third party;</li>
                                                    <li>Harass, bully, or unlawfully discriminate against staff or third parties;</li>
                                                    <li>Make false or misleading statements, or</li>
                                                    <li>Impersonate colleagues or third parties.</li>
                                                </ul>
                                            </li>
                                            <li>You must not express opinions on our behalf via social media unless expressly authorized to do so by your manager. You may be required to undergo training to obtain this authorization.</li>
                                            <li>You must not post comments about sensitive business-related topics, such as our performance, or do anything to jeopardize our trade secrets, confidential information, and intellectual property. You must not include our logos or other trademarks in any social media posting or your profile on any social media.</li>
                                            <li>You are not permitted to add students of the college you meet during class to personal social networking accounts.</li>
                                        </ol>
                                    </li>

                                    <li className='h2li'>
                                        <span>Business use of social media</span>
                                        <ol className='pContanier'>
                                            <li>If your duties require you to speak on behalf of the organization in a social media environment, you must still seek approval for that communication from the PM.</li>
                                            <li>Likewise, if you are contacted for comments about the organization for publication anywhere, including in any social media outlet, direct the inquiry to the PM and do not respond without written approval.</li>
                                            <li>The use of social media for business purposes is subject to the remainder of this policy.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Guidelines for responsible use of social media</span>
                                        <ol className='pContanier'>
                                            <li>You should clearly state in social media postings or your personal profile that you are speaking on your behalf. Write in the first person and use a personal email address.</li>
                                            <li>Be respectful to others when making any statement on social media and be aware that you are personally responsible for all communications published online for anyone to see.</li>
                                            <li>If you disclose your affiliation with us on your profile or in any social media postings, you must state that your views do not represent those of your employer (unless you are authorized to speak on our behalf as set out in paragraph 6.3). You should also ensure that your profile and any content you post are consistent with the professional image you present to clients and colleagues.</li>
                                            <li>If you are uncertain or concerned about the appropriateness of any statement or posting, refrain from posting until you have discussed it with your line manager.</li>
                                            <li>If you see social media content that disparages or reflects poorly on us, you should contact the PM</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Monitoring</span>
                                        <ol className='pContanier'>
                                            <li>We reserve the right to monitor, intercept, and review, without further notice, your activities using our IT resources and communications systems, including but not limited to social media postings and activities, for legitimate business purposes, which include:
                                                <ul>
                                                    <li>Ascertaining and demonstrating that in using the systems, you are meeting expected standards and</li>
                                                    <li>The detection and investigation of unauthorized use of the systems (including where this is necessary to prevent or detect crime).</li>
                                                </ul>
                                            </li>
                                            <li>For further information, see our IT and Communications Systems Policy on our compliance portal.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Recruitment</span>
                                        <ol className='pContanier'>
                                            <li>We may use internet searches to perform due diligence on candidates during recruitment. Where we do this, we will act in accordance with our data protection and equal opportunities obligations.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Breach of this policy</span>
                                        <ol className='pContanier'>
                                            <li>Breach of this policy may result in disciplinary action up to and including dismissal. If we suspect you have committed a breach of this policy, you are required to cooperate with our investigation.</li>
                                            <li>You may be required to remove any social media content that we consider a breach of this policy. Failure to comply with that request may result in disciplinary action.</li>
                                        </ol>
                                    </li>
                                </ol>
                                <p>The latest approved version of this document supersedes all other versions, upon receipt of the latest approved version all other versions should be destroyed, unless specifically stated that previous version(s) are to remain extant. If in any doubt, please contact the document Author.</p>
                                <p>Approved By: Dr Jasmin Thoria, Dr Kuldipsinh Gohil, Sukhdeep Kaeda Date Published: 28/06/2024</p>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-four">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Refund Policy</h1>
                                <h1>Our privacy policy</h1>
                                <ul>
                                    <li>You can cancel for a full refund within 14 days of booking the course.</li>
                                    <li>After 14 days and up to 25 days before the start of the course, you can cancel
                                        and are entitled to a refund of 75%.</li>
                                    <li>14 days or less to the start of the course you can cancel but you are not
                                        entitled to a refund.</li>
                                    <li>If you book a course within 25 days of the start date, you are not entitled to a
                                        refund.</li>
                                </ul>
                                <h1>Please note:</h1>
                                <ul>
                                    <li>Occasionally, due to unforeseen circumstances, a course may have to be
                                        canceled. In this situation, we will endeavor to give as much notice as
                                        possible and you will receive a full refund for the course cost. We cannot,
                                        however, refund other incurred expenses, for example, pre-purchased travel
                                        or accommodation.</li>
                                </ul>
                                <h1>Course certificates</h1>
                                <ul>
                                    <li>At the end of your course, you will be issued with a CPD certificate. If you
                                        need this certificate reissued in the future, there will be a £20 administration
                                        fee payable.</li>
                                </ul>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default ConfidentialityPolicy;
